<template>
  <div>
    <p class="mb-3">Determine how the entropy would change in each of the following processes.</p>

    <v-form @submit.prevent="submitResponse">
      <p>a) <chemical-latex content="He(l) -> He(g)" /></p>
      <v-radio-group v-model="inputs.input1" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p>b) <chemical-latex content="CO2(g) -> CO2(s)" /></p>
      <v-radio-group v-model="inputs.input2" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p>
        c)
        <chemical-latex content="Al(s, -15^\circ C) -> Al(s, -196^\circ C)" />
      </p>
      <v-radio-group v-model="inputs.input3" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question276',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options1: [
        {expression: '$\\text{Increases}$', value: 'increase'},
        {expression: '$\\text{Decreases}$', value: 'decrease'},
        {expression: '$\\text{No change}$', value: 'noChange'},
      ],
    };
  },
};
</script>
